.text-box {
  bottom: -20px;
  right: -20px;
  pointer-events: none;
  &::after,
  &::before {
    content: "";
    position: absolute;
    background-color: white;
    bottom: 0;
    right: 0;
  }
  &::after {
    width: 20px;
    height: 60px;
  }
  &::before {
    width: 60px;
    height: 20px;
  }
}
