.Dashboard .auth-box {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  transition: box-shadow 250ms;
  i.bi,
  h6 {
    transition: transform 250ms;
  }
  &:hover {
    box-shadow: 0 0.5rem 1rem rgb(240 179 29 / 25%);
    i.bi,
    h6 {
      transform: scale(1.25);
    }
  }
}
