$white: white;
$blue: #657fe4;
$dark-blue: #012766;
$light-blue: #d5e1f3;
$violet: #a82070;
$light-yellow: #f8da92;

$primary: #f0b31d;
$info: #00beff;
$success: #15cf74;
$danger: #f55b5d;
$secondary: #818181;
$dark: #364251;
$light: #f1f2f1;
$gray: #9e9e9e;
$light-gray: #d6d7e3;
$light-primary: #f8db93;

$gradient-left-to-right: linear-gradient(to right, #e09230, $primary);
$gradient-top-to-bottom: linear-gradient(to bottom, #c9644a, $primary);

$alert-margin-bottom: 0;
$alert-border-radius: 0.5rem;
$alert-link-font-weight: normal;
$alert-bg-scale: -90%;
$alert-border-scale: 0%;
$alert-color-scale: -100%;

$custom-colors: (
  "white": $white,
  "blue": $blue,
  "dark-blue": $dark-blue,
  "light-blue": $light-blue,
  "primary": $primary,
  "dark": $dark,
  "light": $light,
  "violet": $violet,
  "light-yellow": $light-yellow,
  "secondary": $secondary,
  "gray": $gray,
  "light-gray": $light-gray,
  "info": $info,
  "success": $success,
  "danger": $danger,
);
$custom-font-sizes: (
  7: $font-size-base * 0.8,
  8: $font-size-base * 0.6,
);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-bg-colors: map-loop($theme-colors, rgba-css-var, "$key", "bg");
$font-sizes: map-merge($font-sizes, $custom-font-sizes);
