.Loading {
  inset: 0;
  z-index: 10000;
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  .spinner {
    border-width: 5px !important;
    animation: rotate 1s linear infinite;
    &:nth-child(1) {
      width: 150px;
      height: 150px;
      border-left-color: transparent !important;
    }
    &:nth-child(2) {
      width: 100px;
      height: 100px;
      animation-direction: reverse;
      border-right-color: transparent !important;
    }
    &:nth-child(3) {
      width: 50px;
      height: 50px;
      border-left-color: transparent !important;
    }
  }
}
@keyframes rotate {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
