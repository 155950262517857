.Landing {
  .Navbar {
    > * {
      padding: 0 0.25rem;
    }
    > a {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 3px;
        transition: 250ms;
        transform: scaleX(0);
        background-color: var(--bs-primary);
      }
      &.active {
        font-weight: bold;
        color: var(--bs-dark);
      }
      &.active::before {
        transform: scaleX(1);
      }
    }
  }
}
