.CheckBox {
  label {
    > input {
      &:checked + div div {
        opacity: 1;
      }
    }
    > div {
      width: 20px;
      height: 20px;
      min-width: 20px;
      > div {
        inset: 0;
        border-radius: inherit;
        opacity: 0;
      }
    }
  }
}
