#root {
  max-width: 1800px;
  margin: 0 auto;
}
* {
  margin: 0;
  padding: 0;
  font-family: "iransans-fa";
  box-sizing: border-box;
  outline: none;
}
html,
body {
  font-size: 16px;
  background-color: #fafafa;
  color: $dark;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: inherit;
  }
}
button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  &:disabled {
    pointer-events: all !important;
    cursor: not-allowed;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
p {
  margin-bottom: 0;
  color: $secondary;
  overflow-wrap: break-word;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
hr {
  background-color: $dark;
  margin: 0 auto;
  opacity: 1;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: normal;
}
.wrapper {
  @extend .bg-white, .p-3, .shadow-sm, .rounded-5;
}
.btn-group {
  flex-direction: row-reverse;
}
.form-control[readonly] {
  background-color: white;
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-menu {
  min-width: 100%;
  text-align: start;
}
.dropdown {
  width: fit-content;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.row {
  margin-left: auto;
  margin-right: auto;
  row-gap: 1rem;
  width: 100%;
  @extend .flex-center;
}
.w-fit {
  width: fit-content;
}
.h-fit {
  height: fit-content;
}
.object-fit-contain,
.object-fit-cover {
  object-position: center;
}
.bi,
.bi::before,
.bi::after {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}
.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lh-normal {
  line-height: normal;
}
.top-0 {
  top: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.inset-0 {
  inset: 0;
}
.Sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  transition: 250ms;
  background-color: transparent;
  pointer-events: none;
  .hide-btn {
    flex: 1;
    opacity: 0;
    min-width: 0;
  }
  .items {
    width: 300px;
    height: 100%;
    overflow: auto;
    background-color: white;
    padding: 1rem;
    transition: 250ms;
    transform: translateX(100%);
  }
  &.active {
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: all;
    .items {
      transform: translateX(0%);
    }
  }
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.input-group-text {
  background-color: white;
  color: $gray;
}
