.Authentication {
  .wallet-icon-section {
    width: 95px;
    height: 95px;
    img {
      transform: translate(-22.5%, 22.5%);
    }
  }
  .steps {
    .step {
      width: 80px;
      .icon {
        width: 40px;
        height: 40px;
        background-color: var(--bs-light-gray);
        color: var(--bs-light-gray);
        transition: 250ms;
      }
      h6 {
        color: var(--bs-light-gray);
        font-size: 10px;
        transition: 250ms;
      }
      &.active {
        .icon {
          background-color: var(--bs-success);
          color: white;
        }
        h6 {
          color: var(--bs-success);
        }
      }
    }
  }
  .line {
    height: 12px;
    top: calc(1.5rem + 20px - 6px);
    left: calc(3rem + 40px);
    right: calc(3rem + 40px);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      transition: 500ms;
      width: var(--width);
      background-color: var(--bs-success);
    }
  }
}
