.SuccessfulPurchase {
  .Line {
    &::before {
      content: "";
      position: relative;
      width: 100%;
      border: 2px dashed var(--bs-primary);
      left: 0;
      bottom: calc(50%);
    }
  }
}
