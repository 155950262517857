.NewTicket {
  .file-section {
    > button {
      min-height: 160px;
      background: #fafbfd;
      border: 2px dashed #d8e1ea;
      border-radius: 0.25rem;
      margin-bottom: 15px;
    }
  }
}
