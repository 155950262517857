.accordion-button {
  &::after {
    display: none;
  }
  &:not(.collapsed) {
    .icon {
      transform: rotate(180deg);
    }
  }
  .icon {
    width: 25px;
    height: 25px;
  }
}
