.BlogCard {
  .img-box {
    width: 100%;
    aspect-ratio: 2/1;
  }
  .date {
    top: 25px;
    right: -15px;
    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 100%;
      top: 0;
      left: -20px;
      background-color: var(--bs-primary);
      clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }
    &::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: var(--bs-primary);
      filter: brightness(0.75);
      bottom: -15px;
      right: 0;
      clip-path: polygon(100% 0, 0 0, 0 100%);
    }
  }
}
