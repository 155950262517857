.Users {
  .sidebar {
    .sidebar-menu {
      background-image: linear-gradient(to bottom, #f0b21e, #c9644b);
      min-height: calc(100vh - 2rem);
      overflow-x: hidden;
    }
  }
  .profile {
    width: calc(100% - 2rem);
    .content {
      background-color: rgba(224, 145, 48, 0.8);
    }
  }
  .nav-items li {
    > a {
      position: relative;
      border-radius: 0 1rem 1rem 0;
      i {
        opacity: 0;
      }
      &.active i {
        opacity: 1;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        pointer-events: none;
        display: none;
      }
      &::before {
        top: -30px;
        box-shadow: -15px 15px 0 0px white;
      }
      &::after {
        bottom: -30px;
        box-shadow: -15px -15px 0 0px white;
      }
      &.active::before,
      &.active::after {
        display: block;
      }
    }
    > .accordion {
      --bs-bg-opacity: 0;
      border-radius: 0 1rem 1rem 0;
      background-color: transparent;
      transition: background-color 250ms;
      .accordion-toggler {
        color: white;
        border-radius: inherit;
        background-color: transparent;
        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 0;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          pointer-events: none;
          display: none;
        }
        &::before {
          top: -30px;
          box-shadow: -15px 15px 0 0px white;
        }
        &::after {
          bottom: -30px;
          box-shadow: -15px -15px 0 0px white;
        }
      }
      .accordion-collapse {
        border-radius: inherit;
        height: 0;
        overflow: hidden;
        transition: 250ms linear;
        .accordion-body {
          padding: 0.5rem 1rem;
          div a i {
            opacity: 0;
            transition: 250ms;
          }
          div a.active i {
            opacity: 1;
          }
        }
      }
      &.active {
        --bs-bg-opacity: 0.25;
        .accordion-toggler {
          background-color: white;
          color: var(--bs-violet);
          &::before,
          &::after {
            display: block;
          }
        }
      }
    }
  }
  .breadcrumb {
    .active {
      color: var(--bs-info);
    }
  }

  @media (max-width: 991px) {
    .sidebar {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      display: flex;
      transition: background-color 250ms;
      pointer-events: none;
      .hide-btn {
        flex: 1;
        opacity: 0;
        min-width: 0;
      }
      .sidebar-menu {
        width: 300px;
        height: 100%;
        // overflow: auto;
        overflow-x: hidden;
        background-color: white;
        transition: transform 250ms;
        transform: translateX(100%);
      }
      &.active {
        background-color: rgba(0, 0, 0, 0.5);
        pointer-events: all;
        .sidebar-menu {
          transform: translateX(0%);
        }
      }
    }
  }
}
