.BuyCrypto {
  .cryptoSymbol i {
    // transform: rotateZ(15deg);
  }
  .payment-section {
    > div {
      min-height: 200px;
    }
  }
  .StepIndex {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: var(--bs-primary);
      left: 0;
      top: calc(50% - 0.5px);
    }
    span {
      width: 35px;
      height: 35px;
      z-index: 1;
      padding-top: 2.5px;
    }
  }
}
