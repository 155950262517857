.Input {
  width: 100%;
  height: fit-content;
  .input-box {
    width: 100%;
    border-radius: 0.25rem;
    transition: 150ms;
    border: 1px solid #ced4da;
    input,
    textarea {
      &::placeholder {
        color: var(--bs-secondary);
      }
    }
    input {
      height: 40px;
    }
    textarea {
      min-height: 160px;
    }
    .input-group-text {
      border: none;
    }
  }
  .message {
    min-height: 15px;
    font-size: 0.6rem;
  }
  &.focused {
    .input-box {
      border-color: #86b7fe;
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    }
  }
}
