.Radio {
  label {
    > input {
      &:checked + div div {
        transform: scale(1);
      }
    }
    > div {
      width: 17.5px;
      height: 17.5px;
      > div {
        inset: 2px;
        border-radius: inherit;
        transform: scale(0);
      }
    }
  }
}
