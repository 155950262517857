.Ticket {
  .messages {
    .message {
      .attachment {
        height: 50px;
        > div {
          width: 50px;
          height: 100%;
          .bi-download {
            inset: 10px;
            z-index: 2;
          }
        }
        h6 {
          width: 150px;
        }
      }
      &::before {
        opacity: 1;
        bottom: 15px;
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        pointer-events: none;
      }
      &.user {
        margin-left: auto;
        background-color: var(--bs-primary);
        &::before {
          left: calc(100% - 2px);
          box-shadow: -7.5px 5px 0 1px var(--bs-primary);
        }
      }
      &.admin {
        margin-right: auto;
        background-color: var(--bs-dark);
        &::before {
          right: calc(100% - 2px);
          box-shadow: 7.5px 5px 0 1px var(--bs-dark);
        }
      }
    }
  }
}
